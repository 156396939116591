import React, { Suspense, lazy } from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import './App.css';

// 'Main' Containers
import ContainerLoading from './js/Containers/ContainerLoading';

// Lazy containers
const HomeContainer = lazy(() => import('./js/Containers/HomeContainer'))
const ProjectContainer = lazy(() => import('./js/Containers/ProjectContainer'))
const TeamContainer = lazy(() => import('./js/Containers/TeamContainer'))
const DonateContainer = lazy(() => import('./js/Containers/DonateContainer'))
const FormContainer = lazy(() => import('./js/Containers/FormContainer'))



const NoMatch = ({ location }) => (
	<div className="container pt-5">
		<strong>Sorry, but that page wasn't found: </strong> 
		<code>{location.pathname}</code>	
	</div>
);


class App extends React.Component {

	render() {

		return (
			<div className="App">
				<BrowserRouter>
					<Suspense fallback={<ContainerLoading/>}>
						<Switch>
							<Route path='/donate/:teamid' component={DonateContainer} />
							<Route path='/team/:teamid' component={TeamContainer} />
							<Route path='/project/:projectid' component={ProjectContainer} />
							<Route path='/form/:formid' component={FormContainer} />
							<Route exact path='/' component={HomeContainer} />
							<Route component={NoMatch} />
						</Switch>
					</Suspense>
				</BrowserRouter>
			</div>

		);
	}
}



export default App;
